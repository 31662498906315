<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog1"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card
          class="mx-auto mt-16 margen info-card"
          elevation="9"
          height="90%"
          width="55%"
        >
            <v-toolbar
              flat
            >

              <v-btn 
                @click="volver()"
                icon
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-toolbar>

            <v-avatar
              class="mx-auto profile mb-16 mt-n10"
              size="250"
              rounded
            >
              <v-img
                src="../assets/Logos/dataset.png"
                transition="scale-transition"
              />
            </v-avatar>

            

          <v-card-text class="mt-n16">
            <div class="text-h6 mb-2">
              {{datasetActual.Nombre}}
            </div>

            <v-divider class="my-5"></v-divider>

            <div class="parrafos mx-5">

              <p
                v-for="(parrafo, i) in datasetActual.Descrip" 
                :key="i"
              >
                {{parrafo}}
              </p>

              <strong> {{datasetActual.Nombre}} contains: </strong>

              <ul>
                <li
                  v-for="(contenido, i) in datasetActual.Contenido" 
                  :key="i"
                >
                  {{contenido}}
                </li>
              </ul>

            <br/>
            <strong> Cite </strong>
            <br/>

            {{citaActual}}

            </div>
          </v-card-text>

          <v-btn
            v-bind="attrs"
            v-on="on"
            class="my-16"
            color="#344898"
            dark
          >
            Download dataset
          </v-btn>

        </v-card>
      </template>

      <div v-if="controlador===1">
        <v-card>
          <v-card-title>
            <v-spacer/>
            <span class="text-h5">Please complete this form before download</span>
            <v-spacer/>
          </v-card-title>
          <v-form 
            ref="form"
            v-model="valid"
            lazy-validation
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="name"
                    label="First name*"
                    required
                    :rules="nameRules"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="lastname"
                    label="Last name*"
                    persistent-hint
                    required
                    :rules="lastnameRules"
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="email"
                    label="Email*"
                    required
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="institution"
                    required
                    :rules="institutionRules"
                    label="Institution*"
                  ></v-text-field>

                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="boxText"
                    outlined
                    required
                    auto-grow
                    :rules="boxRules"
                    label="Purpose of your download*"
                  ></v-textarea>

                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog1 = false"
            >
              Close
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              text
              @click="validate()"
            >
              Send
            </v-btn>
          </v-card-actions>
          </v-form>
          <v-dialog
            v-model="dialog2"
            hide-overlay
            persistent
            width="300"
          >
            <v-card
              color="#344898"
              dark
            >
              <v-card-text>
                Please stand by
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>

        </v-card>
      </div>
      <div v-if="controlador===2">
        <v-card class="scale-up-center">
          <v-card-title>
            <v-spacer/>
            <span class="text-h5 contenido">Your download is ready</span>
            <v-spacer/>
          </v-card-title>

          <v-btn
            download
            :href="datasetActual.File"
            class="my-6 contenido"
            color="#344898"
            dark
            @click="dialog1=false;controlador=1;name = '';lastname = '';email = '';boxText = '';institution=''"
          >
            Download
          </v-btn>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>



<script>
import emailjs from 'emailjs-com';

export default{
  data () {
    return {
      valid:false,
      controlador:1,
      dialog1: false,
      dialog2: false,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      lastname: '',
      lastnameRules: [
        v => !!v || 'Last name is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      institution:'',
      institutionRules:[
        v => !!v || 'Institution is required',
      ],
      boxText: '',
      boxRules: [
        v => !!v || 'Description is required',
      ],
      datasetActual: Object,
      citaActual:'',
      datasets:[
        {
          Nombre:'SCIAN-MorphoSpermGS',
          Publication: {
            auth:'Violeta Chang, Alejandra García, Nancy Hitschfeld, Steffen Härtel',
            year:'2017',
            article:'Gold-standard for computer-assisted morphological sperm analysis',
            publisher:'Computers in Biology and Medicine (CBM),83 (2017): 143-150'
          },
          Descrip:[
            'SCIAN-MorphoSpermGS consists of 1,132 images of sperm heads of 35 x 35 pixels obtained from human semen samples from the Spermiograms Laboratory, Developmental Anatomy and Biology Program of the Faculty of Medicine of the Universidad de Chile.',
            'Each of the sperm heads have been manually and independently classified by three leading national experts in the area of sperm morphology, within one of the following head classes: normal, elongated, pyriform, small, amorphous.',
            'The 1,132 sperm heads have partial concordance between expert opinions, that is, at least two experts agree on the assigned class.'
          ],
          Contenido:[
            '1,132 images in TIFF format.',
            'clases.txt with definition of classes and labels ',
            'PA-expert-annotations.txt with manual classification of 3 experts and consolidated label by simple majority'
          ],
          Areas:[
            {
              name:'Medicine'
            },
            {
              name:'Biomedical'
            },
          ],
          File: '/files/SCIAN-MorphoSpermGS.zip'
        },

        {
          Nombre:'SCIAN-SpermSegGS',
          Publication: {
            auth:'Violeta Chang, Jose M. Saavedra, Victor Castañeda, Luis Sarabia, Nancy Hitschfeld, Steffen Härtel',
            year:'2014',
            article:'Gold-standard and improved framework for sperm head segmentation',
            publisher:'Computer Methods and Programs in Biomedicine (CMPB), 117(2): 225-237'
          },
          Descrip:[
            'SCIAN-SpermSegGS consists of 210 spermatozoa contained in 20 digital images of 780 x 580 pixels obtained from human semen samples from the Spermiograms Laboratory, Developmental Anatomy and Biology Program of the Faculty of Medicine of the Universidad de Chile. ',
            'For each of the spermatozoa, a leading expert in the area of sperm morphology has manually segmented its constituent parts (head, acrosome, nucleus, midpiece and tail).'
          ],
          Contenido:[
            '20 RGB images in JPEG format ',
            '20 binary sperm head masks',
            '20 binary masks of sperm acrosomes',
            '20 binary masks of sperm nuclei',
            '20 binary masks of sperm midpieces',
            '20 Binary Sperm Tail Masks '
          ],
          Areas:[
            {
              name:'Medicine'
            },
            {
              name:'Biomedical'
            },
          ],
          File: '/files/SCIAN-SpermSegGS.zip'
        },
      ]
    }
  },
  watch: {
    dialog2 (val) {
      if (!val) return

      setTimeout(() => (this.dialog2 = false, this.controlador = 2), 3000)
    },
  },
  name: 'Dataset',
  methods:{
    volver(){
      this.$router.go(-1)
    },
    cargarData(nombre){
      for(let i = 0; i < this.datasets.length ; i++){
        if (this.datasets[i].Nombre == nombre.split('_').join(' ')){
          this.datasetActual = this.datasets[i]
        }
      }

      this.citaActual = this.datasetActual.Publication.auth + " (" + this.datasetActual.Publication.year + "). " + this.datasetActual.Publication.article +". " + this.datasetActual.Publication.publisher

      return 0;
    
    },
    sendEmail() {
        emailjs.send('service_jsgv3b9', 'template_ngm0ka2',
        {
          to_name: this.name + ' ' + this.lastname,
          to_email: this.email,
          dataset: this.datasetActual.Nombre,
          cite: this.citaActual
        },
        'user_bzodBsqyz8bXHvSeoSCsu')
            .then((result) => {
                console.log('SUCCESS!', result.text);
            }, (error) => {
                console.log('FAILED...', error.text);
            });

    },
    validate () {
      if( this.$refs.form.validate() ){
        this.sendEmail();
        this.dialog2 = true;
      }
    },
  },
  created (){
    this.cargarData(this.$route.params.dataset)
  }
}
</script>

<style scoped>
  .parrafos{
    text-align: justify;
  }
  .info-card{
    margin:0 auto 132px;
    width:75%;
    opacity: 0;
    animation: slide-top 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  @keyframes slide-top {
    0% {
      opacity: 0;
      transform: translateY(1000px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>